
.audit-list-item {
    background-color: $okcomply-white;
    margin-bottom: 1rem;
    padding-bottom: 0;
    text-decoration: none;
    color: $okcomply-black;
    &:hover {
        box-shadow: $list-item-box-shadow;
    }

    .list-item-rows {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        @include mobile {
            flex-direction: column;
        }
    }

    .list-item-row-single {
        display: flex;
        flex: 1;
        flex-direction: row;
        &.reverse-direction {
            flex-direction: row-reverse;
        }
    }

    .list-item-label-with-tooltip {
        position: relative;
        flex: 1;

        .tooltip-desktop {
            right: 0;
            top: 3.3rem;
            font-weight: 400;
        }
        &:hover {
            color: $okcomply-blue;
            .tooltip-desktop {
                display: block;
            }
        }
    }

    .list-item-label {
        flex: 1;
        align-self: center;

        &.bold {
            font-weight: bold;
        }

        p {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            color: #000000;
        }

        @include tablet-up {
            span.has-padding {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
        .is-flex {
            align-items: center;
        }
        span.has-padding-right {
            padding-right: 10px;
        }
    }

    .list-item-icons {
        display: flex;
        flex-grow: 0;
        color: red;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        a {
            color: $okcomply-black;
            &:hover {
                color: $okcomply-blue;
            }
        }
    }
}
