@import "./variables.scss";
@import "../sass-import-once.scss";

@include exports("common") {

  .is-on-top {
    z-index: 100;
  }

  .is-divider {
    padding-top: 1rem;
    margin-bottom: 1rem;
  }

  .subtitle {
    color: $okcomply-blue;
    margin-bottom: 1rem !important;
  }

  .is-pulled-right {
    float: right !important;
  }

  .no-outline {
    outline: none;
  }

  .is-relative {
    position: relative;
  }

  // Simple columns implementation without
  // adding additional spacing
  .cols {
    @include mobile {
      .col {
        margin-bottom: 1rem;
      }
    }

    @include desktop {
      display: flex;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      .col {
        flex: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        &.is-half-desktop {
          flex: 0.5 !important;
        }
      }

      &.spacing-double {
        margin-left: -1rem;
        margin-right: -1rem;

        .col {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }

    @include tablet-up {
      display: flex;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      .col {
        flex: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      &.spacing-double {
        margin-left: -1rem;
        margin-right: -1rem;

        .col {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .cols-m {
    display: flex;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .col {
      flex: 1;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .line-horizontal {
    width: 100%;
    height: 1px;
    background-color: gray;
  }

  .container-buttons {
    display: flex;
    justify-content: flex-end;

    &.space-between {
      .input:not(:last-child), button:not(:last-child) {
        margin-right: $spacing-single;
      }
    }

    @include mobile {
      justify-content: space-between;
      .button {
        flex: 1;
      }
    }
  }

  .spacer {
    height: 1rem;
    min-height: 1rem;
    width: 1px;
  }

  .spacer-double {
    height: 2rem;
    min-height: 2rem;
    width: 1px;
  }

  .divider-line {
    width: 100%;
    height: 0.5px;
    background-color: $okcomply-gray;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .container-no-nav {
    margin-bottom: -4.5rem;
  }

  .container-card-centered {
    padding: 2rem;
    width: 430px;
    background-color: $okcomply-white;
    box-shadow: $form-shadow;
    @include mobile {
      width: 100%;
      margin: 1rem;
    }
  }

  .container-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $okcomply-gray-overlay;
    color: $okcomply-white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 999;
    animation: fadeInOverlay 1s forwards;
  }

  @keyframes fadeInOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $okcomply-green !important;
  }
}
