@import "./sass-import-once.scss";

@include exports("spacing") {
    html {
        --spacing-half: 0.5rem;
        --spacing-single: 1rem;
        --spacing-double: 2rem;
    }

    @include mobile {
        html {
            --spacing-half: 0.25rem;
            --spacing-single: 0.5rem;
            --spacing-double: 1rem;
        }
    }
}

$spacing-half: var(--spacing-half);
$spacing-single: var(--spacing-single);
$spacing-double: var(--spacing-double);
