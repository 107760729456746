@import "../../style/main.scss";

.okcomply-snackbar {
    display: flex;
    position: fixed;
    box-shadow: $card-shadow;
    justify-content: space-between;
    align-items: center;
    bottom: 1rem;
    min-width: 50vw;
    z-index: 9999;
    background-color: $okcomply-blue;
    color: white;
    transition: opacity 2s ease-in-out;
    opacity: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin: auto;
    border-radius: 3px;
    padding: 1rem;
    &.error {
        background-color: $okcomply-red;
    }
    .snackbar-text {
        flex: 1;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    &.show {
        opacity: 1;
    }
    &.hide {
        opacity: 0;
    }
}
