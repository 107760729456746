@import "../sass-import-once.scss";

@include exports("typography") {
  // Contains typography based the Zeplin stylesheet
  // url:
  $text-font-stack: ("ProximaNova", "OpenSans", sans-serif);
  $text-color: $okcomply-black;
  $text-font-weight: 400;
  $label-font-weight: 600;

  body,
  button,
  input,
  select,
  textarea,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  label {
    color: $text-color;
    font-weight: $text-font-weight;
    font-family: $text-font-stack;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $okcomply-blue;
    &.light {
      color: $okcomply-white;
    }
    &.danger {
      color: $okcomply-red;
    }
  }

  h1 {
    font-size: 40px;
    line-height: 40px;
    word-wrap: break-word;
    margin-bottom: 2rem;
    @include tablet {
      font-size: 30px;
      margin-bottom: 1rem;
    }
    @include mobile {
      font-size: 25px;
      margin-bottom: 0.5rem;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
  }

  h4 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    padding-bottom: 0.5rem;
  }
}
