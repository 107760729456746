@import "../sass-import-once.scss";

@include exports("trengo") {
  #trengo-web-widget {
    z-index: 1 !important;
  }

  .TrengoWidgetLauncher__iframe,
  .TrengoWidgetGreeter__iframe,
  .TrengoWidgetBadge__iframe {
    z-index: 1 !important;
  }
}
