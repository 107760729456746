@import "./style/app-style.scss";

html {
    height: 100%;
    background-color: $body-background-color;
    overflow: auto;
}
body {
    height: 100%;
    margin: 0;
}

.App, #root {
    height: 100%;
}