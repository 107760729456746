$success: hsl(141, 71%, 48%);
$okcomply-gray: #dddddd;
$okcomply-gray: #dddddd;
$okcomply-gray-invert: findColorInvert($okcomply-gray);
$okcomply-gray-overlay-light: rgba(0, 0, 0, 0.05);
$okcomply-gray-overlay: rgba(0, 0, 0, 0.3);
$okcomply-gray-darker: rgba(0, 0, 0, 0.4);
$okcomply-gray-darkest: rgba(0, 0, 0, 0.6);
$okcomply-gray-lighter: #f8f8f8;
$okcomply-gray-form: #f3f3f3;
$okcomply-gray-lighter-invert: findColorInvert($okcomply-gray-lighter);
$okcomply-gray-light: #d3d3d3;
$okcomply-gray-light-invert: findColorInvert($okcomply-gray-light);
$okcomply-yellow: #e8d81d;
$okcomply-yellow-invert: findColorInvert($okcomply-yellow);
$okcomply-yellow-bis: #e9a944;
$okcomply-yellow-bis-invert: findColorInvert($okcomply-yellow-bis);
$okcomply-red: #ff0000;
$okcomply-red-invert: findColorInvert($okcomply-red);
$okcomply-red-bis: #cd2433;
$okcomply-red-bis-invert: findColorInvert($okcomply-red-bis);
$okcomply-red-lighter: #d64e2c;
$okcomply-red-lighter-invert: findColorInvert($okcomply-red-lighter);
$okcomply-red-validation: #f44336;
$okcomply-red-invert: findColorInvert($okcomply-red-validation);
$okcomply-orange: #f2882c;
$okcomply-orange-invert: findColorInvert($okcomply-orange);
$okcomply-green: #33de8e;
$okcomply-green-invert: findColorInvert($success);
$okcomply-green-light: #35d176;
$okcomply-green_invert: findColorInvert($okcomply-green-light);
$okcomply-blue: #006280;
$okcomply-blue-invert: findColorInvert($okcomply-blue);
$okcomply-blue-teal: rgba(32,115,140,255);
$okcomply-blue-teal-invert: findColorInvert($okcomply-blue-teal);
$okcomply-blue-bis: #3172d7;
$okcomply-blue-bis-invert: findColorInvert($okcomply-blue-bis);
$okcomply-blue-hover: #95bfca;
$okcomply-blue-hover-invert: findColorInvert($okcomply-blue-hover);
$okcomply-blue-lighter: #d6e9eb;
$okcomply-blue-lighter-invert: findColorInvert($okcomply-blue-lighter);
$okcomply-blue-light: #33c2e9;
$okcomply-blue-light-invert: findColorInvert($okcomply-blue-light);
$okcomply-blue-alt: #3e8097;
$okcomply-blue-alt-inverse: findColorInvert($okcomply-blue-alt);
$okcomply-white: #ffffff;
$okcomply-white-invert: findColorInvert($okcomply-white);
$okcomply-black: #000000;
$okcomply-black-invert: findColorInvert($okcomply-black);
$okcomply-danger: $okcomply-red;
$okcomply-danger-invert: findColorInvert($okcomply-danger);
$danger: #ff0000;
$body-background-color: $okcomply-gray-lighter;

$black:        hsl(0, 0%, 4%);
$black-bis:    hsl(0, 0%, 7%);
$black-ter:    hsl(0, 0%, 14%);

$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    hsl(0, 0%, 29%);
$grey:         hsl(0, 0%, 48%);
$grey-light:   hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter:    hsl(0, 0%, 96%);
$white-bis:    hsl(0, 0%, 98%);
$white:        hsl(0, 0%, 100%);

$orange:       hsl(14,  100%, 53%);
$yellow:       hsl(48,  100%, 67%);
$green:        hsl(141, 71%,  48%);
$turquoise:    hsl(171, 100%, 41%);
$cyan:         hsl(204, 86%,  53%);
$blue:         hsl(217, 71%,  53%);
$purple:       hsl(271, 100%, 71%);
$red:          hsl(348, 100%, 61%);

$custom-colors: (
  "okcomply-blue": (
    $okcomply-blue,
    $okcomply-blue-invert
  ),
  "okcomply-blue-light": (
    $okcomply-blue-light,
    $okcomply-blue-light-invert
  ),
  "okcomply-blue-bis": (
    $okcomply-blue-bis,
    $okcomply-blue-bis-invert
  ),
  "okcomply-blue-lighter": (
    $okcomply-blue-lighter,
    $okcomply-blue-lighter-invert
  ),
  "okcomply-green": (
    $okcomply-green,
    $okcomply-green-invert
  ),
  "okcomply-yellow": (
    $okcomply-yellow,
    $okcomply-yellow-invert
  ),
  "okcomply-red": (
    $okcomply-red,
    $okcomply-red-invert
  ),
  "okcomply-orange": (
    $okcomply-orange,
    $okcomply-orange-invert
  ),
  "okcomply-gray": (
    $okcomply-gray,
    $okcomply-gray-invert
  ),
  "okcomply-gray-light": (
    $okcomply-gray-light,
    $okcomply-gray-light-invert
  ),
  "okcomply-gray-lighter": (
    $okcomply-gray-lighter,
    $okcomply-gray-lighter-invert
  ),
  "okcomply-white": (
    $okcomply-white,
    $okcomply-white-invert
  ),
  "okcomply-black": (
    $okcomply-black,
    $okcomply-black-invert
  ),
  "okcomply-danger": (
    $okcomply-danger,
    $okcomply-danger-invert
  )
);


// phase colors
$dossier-phase-color-expected: $okcomply-yellow;
$dossier-phase-color-attention:$okcomply-orange;
$dossier-phase-color-critical: $okcomply-red-lighter;
$dossier-phase-color-late: $okcomply-red;

$report-phase-color-new: $okcomply-blue-bis;
$report-phase-color-planned: $okcomply-yellow-bis;
$report-phase-color-late: $okcomply-red;

// navigation colors
$navbar-menu-background-color: $okcomply-blue;
$navbar-item-background-color: $okcomply-blue;
$navbar-item-hover-background-color: $okcomply-blue-alt;
$navbar-item-hover-color: $okcomply-green-light;
$navbar-item-color: $okcomply-white;
$navbar-item-active-color: $okcomply-green-light;
$navbar-burger-color: $okcomply-white;
$navbar-link-color: $okcomply-green-light;
$navbar-link-active-color: $okcomply-green-light;
$navbar-link-hover-color: $okcomply-green-light;
$navbar-link-hover-background-color: $okcomply-blue-alt;
$navbar-link-active-background-color: $okcomply-blue-alt;
