@import "./main.scss";

body {
    h1 {
        font-size: 40px;
        line-height: 40px;
        word-wrap: break-word;
        color: white;
        margin-bottom: 2rem;
        @include tablet {
            font-size: 30px;
            margin-bottom: 1rem;
        }
        @include mobile {
            font-size: 25px;
            margin-bottom: 0.5rem;
        }
    }

}
.divider {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-bottom: solid 1px $okcomply-gray;
}

.divider-vertical {
    margin-left: 1rem;
    background-color: $okcomply-gray-light;
    margin-right: 1rem;
    width: 1px;
    height: 2rem;
}

#auth0-login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include mobile {
        position: relative;
        width: 100%;
        top: unset;
        left: unset;
        transform: unset;
    }
}

.container-warning {
    top: $spacing-double;
    right: $spacing-double;
    background-color: $okcomply-red;
    color: $okcomply-white;
    border-radius: 3px;
    padding: 0.5rem;
    font-size: 12px;
    display: inline-block;
}

.container-empty {
    top: $spacing-double;
    right: $spacing-double;
    background-color: $okcomply-blue-light;
    color: $okcomply-white;
    border-radius: 3px;
    padding: 0.5rem;
    font-size: 12px;
}