@import "../../style/main.scss";

.statistics-row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  margin-bottom: 1rem;

  @include tablet-down {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.statistics-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 20px;
  color: $okcomply-blue;

  @include tablet-down {
    margin-bottom: 1rem;
    width: 100%;
    flex-basis: 100%;
  }
}

.statistics-column {
  display: flex;
  padding: 0.5rem 1rem 1rem 0;
  flex-direction: column;
  min-width: 100px;
  flex-basis: 0;
  flex-grow: 1;

  @include tablet-down {
    flex-direction: column;
    border-radius: 5px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 2rem);
    width: calc(50% - 2rem);
    border: 1px solid lightgrey;
    margin: 1rem;
  }

  .label {

    display: flex;
    justify-content: center;

    @include tablet-down {
      justify-content: flex-start;
      margin-left: 1rem;
    }
    font-weight: normal;
    font-size: 14px;
    color: #b5b5b5;
  }

  .item {
    display: flex;
    padding-left: 1rem;
    font-size: 18px;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
    align-items: center;
    @include tablet-down {
      align-items: center;
    }
  }

}

